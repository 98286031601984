/* eslint-disable react/prop-types */
/* eslint-disable react/react-in-jsx-scope */
import React from "react";
import { fetchQueryWithCache } from "../functions-ssr";
import HomeDefault from "../components/home/default";
import PRODUCTS_AND_CATEGORIES_QUERY from "../queries/product-and-categories";
import TOPMENU_AND_FOOTERMENU_QUERY from "../queries/topMenu-and-footerMenu";
import CAT_MUJER_HOMBRE_QUERY from "../queries/cat-mujer-hombre";
import { rowCreateByDate } from "../functions";


export default function Home(props) {

	return (
		<HomeDefault {...props} />
	);
}

export async function getServerSideProps(context) {
	try {
		const userAgent = context.req.headers["user-agent"];
		const isMobile = Boolean(
			userAgent.match(
				/Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i
			)
		);

		const data = await fetchQueryWithCache(
			PRODUCTS_AND_CATEGORIES_QUERY,
			{}, // Aquí van las variables si las hay
			"cache_PRODUCTS_AND_CATEGORIES_QUERY",
			14400
		);

		const setting = await fetchQueryWithCache(
			TOPMENU_AND_FOOTERMENU_QUERY,
			{},
			"cache_topmenu_and_footermenu_query",
			84600
		);

		const menu = await fetchQueryWithCache(
			CAT_MUJER_HOMBRE_QUERY,
			{},
			"cache_cat_mujer_hombre_query",
			84600
		);

		let dataWomen = rowCreateByDate(data.mujer.nodes || [], 9);
		let dataMen = rowCreateByDate(data.hombre.nodes || [], 9);

		let cachedData = {};
		cachedData = {
			isMobile: isMobile,
			mujer: dataWomen ? dataWomen : [],
			hombre: dataMen ? dataMen : [],
			options: data?.arenbitThemeLanding ? data?.arenbitThemeLanding : [],
			setting: setting?.arenbitThemeOptions ? setting?.arenbitThemeOptions : [],
			seo: setting?.generalSettings ? setting?.generalSettings : [],
			categories: setting?.menus?.nodes[0]?.menuItems?.nodes ? setting?.menus?.nodes[0]?.menuItems?.nodes : [],
			footerMenu: setting?.footerMenu?.nodes[0] ? setting?.footerMenu?.nodes[0] : [],
			menu: menu ? menu : [],
		};

		return {
			props: cachedData,
		};
	} catch (error) {
		console.error(error);
		throw new Error("Error problemas de conexion con el servidor");
	}
}
